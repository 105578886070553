import { User } from '@/shared/models/user';

const userMockData: User = {
  userProfileId: '69c9033c-9ee9-4cd8-9331-6ef8e50081ea',
  firstName: 'John',
  middleName: 'A',
  lastName: 'Smith',
  email: 'johnsmith@example.com',
  phoneNumber: '+1 8679921888',
};

export default userMockData;
