import { PublicClientApplication } from '@azure/msal-browser';
import './App.css';
import { User } from '@/shared/models/user';
import msalMockData from './mocks/data/msalMockData';
import userMockData from './mocks/data/userMockData';
import AccessPassWidgetMFE from './features/access-pass-widget/AccessPassWidgetMFE';

const msalInstance: PublicClientApplication =
  (window as any).props?.msalInstance ?? msalMockData;

const user: User = (window as any).props?.user ?? userMockData;

function App(): JSX.Element {
  const account = msalInstance.getActiveAccount();

  if (account?.username === 'mock-username') {
    return (
      <center>
        <h2>Hello, {account.name}!</h2>
      </center>
    );
  }

  return <AccessPassWidgetMFE msalInstance={msalInstance} user={user} />;
}

export default App;
