import {
  PublicClientApplication,
  AuthenticationResult,
  IController,
  Configuration,
  AccountInfo,
} from '@azure/msal-browser';

class MockMsalInstance extends PublicClientApplication {
  controller: IController = {} as IController;
}

const msalMockData: MockMsalInstance = new MockMsalInstance(
  {} as Configuration
);

msalMockData.acquireTokenSilent = () =>
  Promise.resolve({} as AuthenticationResult);
msalMockData.acquireTokenPopup = () =>
  Promise.resolve({} as AuthenticationResult);
msalMockData.loginPopup = () => Promise.resolve({} as AuthenticationResult);
msalMockData.getActiveAccount = () =>
  ({
    homeAccountId: 'mock-home-account-id',
    environment: 'mock-environment',
    tenantId: 'mock-tenant-id',
    username: 'mock-username',
    localAccountId: 'mock-local-account-id',
    name: 'Mock Name',
    idToken: `eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjNQYUs0RWZ5Qk5RdTNDdGpZc2EzWW1oUTVFMCJ9.eyJhdWQiOiI5NGFmNmFiYi1mYjFjLTRlNjItOWJhYy1jMTFmNmE2ZjBjNTkiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vMDA2YzFlNDgtZTM0Mi00N2U5LWFiNWQtMGRkOWZmODliZDk2L3YyLjAiLCJpYXQiOjE3MjkyODQ3ODksIm5iZiI6MTcyOTI4NDc4OSwiZXhwIjoxNzI5Mjg4Njg5LCJhaW8iOiJBWFFBaS84WUFBQUF5S044ZEdWdzdVd1RZY1c5Q3BSNGR6WFM2aE5JbTg4WDdXL2NLeVdTa2RQQkJuelpQaFRSWTBpV3hKZEpVY1NTYkdhTjZ4YmNCeHN4NVIvdXJtMCtvaWlBTUM3QVEyZG1zT3JZbXlGNkFWSXFCNTlKclM2RkRPajlNWDlNcjBCKzRDaXd4d1N1eW5WQmVBaXhsRysybFE9PSIsIm5hbWUiOiJUYXJhcyBWbGFzb3YiLCJub25jZSI6IjAxOTI5YzU5LTc1ZTktN2M2Yy1iMmZkLWZmMTlkOTM4MTJlZCIsIm9pZCI6ImNmYzUwOTgzLTViYjktNDFhMC04N2Y1LWFiZWE2NmNkMjljNSIsInByZWZlcnJlZF91c2VybmFtZSI6InR2bGFzb3ZAY2FlLmNvbSIsInJoIjoiMC5BVjhBU0I1c0FFTGo2VWVyWFEzWl80bTlscnRxcjVRYy0ySk9tNnpCSDJwdkRGa1BBY0EuIiwicm9sZXMiOlsiU3VwZXJBZG1pbiJdLCJzdWIiOiJ5Ym04VEdsdFJKV0RFZUZJNnlqbkFrWVFfMlQwdXNYQ2RqcGVWRXZ1RVlzIiwidGlkIjoiMDA2YzFlNDgtZTM0Mi00N2U5LWFiNWQtMGRkOWZmODliZDk2IiwidXRpIjoibV9NbmVwV1h6VUd2TUhjMllmZ3RBQSIsInZlciI6IjIuMCJ9.EtvuuzPDoT3zyXzpVvzP8v51RrQyz_BS8E4zW0v_INp8XtWXyssufJbKuIfe6CtiUrh_Yql7Rnq9yf8RUEXJBTHJTBEWHY2YanbTdWliZ8rdiUvLBwy5VoKK-619-haom-l9d4WwlUYGaE_gTzM3nircxYhD8gAsv5jn6KgrpVu8ZkByOEYj6RuZm0mRyua9HJSgu74SetHTewpQQa1CCZYziYfh3_4FfT1CQG4onNBABka6WEWd5DWuXEcxJG4NTR4cS_of3CVCue-yNw2JutVo78qe8CfgQsCceoboUQvcu8i3dbY6mxZpF--UCmHd0yWtHVFsL4gVznJOOblijg`,
    idTokenClaims: {
      aud: 'mock-aud',
      iss: 'mock-iss',
      iat: 1616161616,
      nbf: 1616161616,
      exp: 1616161616,
      aio: 'mock-aio',
      name: 'Mock Name',
      oid: 'mock-oid',
      preferred_username: 'mock-username',
      sub: 'mock-sub',
      tid: 'mock-tid',
      uti: 'mock-uti',
      ver: 'mock-ver',
    },
  }) as AccountInfo | null;

export default msalMockData;
